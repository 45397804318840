import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import dimensions from "../styles/dimensions"
import Layout from "../components/Layout"
import PostCard from "../components/PostCard"

const BlogTitle = styled("h1")`
  margin-bottom: 1em;
`

const BlogGrid = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5em;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5em;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
    grid-gap: 2.5em;
  }
`

const Blog = ({ posts, meta }) => (
  <>
    <Helmet
      title={`Blog | Mucho que contar. Poco que perder`}
      titleTemplate={`%s | Blog | Mucho que contar. Poco que perder`}
      meta={[
        {
          name: `description`,
          content:
            "A veces nos da por escribir y compartir contigo todo lo que se nos pasa por la cabeza. Inspiración, trucos, actualidad y esas cosas.",
        },
        {
          property: `og:title`,
          content: `Blog | Mucho que contar. Poco que perder.`,
        },
        {
          property: `og:description`,
          content:
            "A veces nos da por escribir y compartir contigo todo lo que se nos pasa por la cabeza. Inspiración, trucos, actualidad y esas cosas.",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content:
            "A veces nos da por escribir y compartir contigo todo lo que se nos pasa por la cabeza. Inspiración, trucos, actualidad y esas cosas.",
        },
      ].concat(meta)}
    />
    <Layout>
      <BlogTitle>Blog</BlogTitle>
      <BlogGrid>
        {posts.map((post, i) => (
          <PostCard
            key={i}
            author={post.node.data.post_author}
            category={post.node.data.post_category}
            title={post.node.data.post_title}
            date={post.node.data.post_date}
            description={post.node.data.post_preview_description}
            uid={post.node.uid}
          />
        ))}
      </BlogGrid>
    </Layout>
  </>
)

const component = ({ data }) => {
  const posts = data.allPrismicPost.edges
  const meta = data.site.siteMetadata
  if (!posts) return null

  return <Blog posts={posts} meta={meta} />
}

export default component

Blog.propTypes = {
  posts: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    allPrismicPost(sort: { fields: data___post_date, order: DESC }) {
      edges {
        node {
          data {
            post_author
            post_date
            post_preview_description {
              richText
              text
            }
            post_category {
              html
              richText
            }
            post_hero_annotation {
              text
              richText
            }
            post_hero_image {
              url
            }
            post_title {
              text
              richText
            }
          }
          uid
        }
      }
    }

    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
